import { Card } from "./FAQCard.elements";

export type TFAQCardProps = {
  title: string;
  description: string;
};

export const FAQCard = ({ title, description }: TFAQCardProps) => {
  return (
    <Card.Container component="article">
      <Card.Header title={title} />
      <Card.Body component="section" dangerouslySetInnerHTML={{ __html: description }} />
    </Card.Container>
  );
};

export const FAQCardSkeleton = () => <Card.LoadingSkeleton />;
