import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import type { AxiosError } from "axios";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { useGetUser } from "../hooks/useGetUser";
import { useUpdateUser } from "../hooks/useUpdateUser";
import useTabActive from "features/Auth/hooks/useTabActive";
import { isWayfinderGeneratedUserName } from "../utils";
import type { User } from "_global/types";
import type { UpdateUserMutationFunc } from "../hooks/useUpdateUser";

const IS_DEBUG_MODE = false;

export const WayfinderUserControllerContext =
  React.createContext<IWayfinderUserControllerContextValue>({
    isLoading: true,
    isFetching: true,
    isError: false,
    error: null,
    isUpdateSuccess: false,
    updateUser: () => undefined,
    wayfinderUser: null,
  });

type WayfinderUserControllerContextProviderProps = {
  children: React.ReactNode;
};

interface IWayfinderUserControllerContextValue {
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  error: AxiosError | null;
  isUpdateSuccess: boolean;
  wayfinderUser: User | null;
  updateUser: UpdateUserMutationFunc;
}

export const WayfinderUserControllerContextProvider: React.FC<
  WayfinderUserControllerContextProviderProps
> = ({ children }) => {
  const { getAccessTokenSilently, ...auth0 } = useAuth0();
  const [token, setToken] = React.useState<string | null>(null);
  const { user, setUser } = useGlobalContext();
  const isTabActive = useTabActive();

  // Refresh stale auth0 state if user logs out in another tab
  const shouldGetFreshToken = auth0.isAuthenticated && isTabActive;
  // Clear user if user logs out in another tab
  const shouldClearUser = !auth0.isAuthenticated && !!user;
  // Fetch user if token is available
  const shouldGetUser = auth0.isAuthenticated && !auth0.isLoading && !!token;

  React.useEffect(() => {
    if (shouldClearUser) setUser(null);
  }, [shouldClearUser, setUser]);

  React.useEffect(() => {
    if (!shouldGetFreshToken) return;
  
    setToken(null);

    const fetchToken = async () => {
      try {
        const tkn = await getAccessTokenSilently();
        setToken(tkn);
      } catch (error) {
        if (IS_DEBUG_MODE) console.trace("DEV ERROR - WayfinderUserController", error);
      }
    };
    fetchToken();
  }, [shouldGetFreshToken, getAccessTokenSilently]);

  const { data, isFetching, isLoading, isError, error } = useGetUser({
    token,
    enabled: shouldGetUser,
  });

  const {
    mutate: updateUser,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
  } = useUpdateUser();

  const wayfinderUser = React.useMemo(() => {
    if (!data) return null;

    const firstName = data.firstName ?? "";
    const lastName = data.lastName ?? "";
    const isWayfinderGeneratedName = isWayfinderGeneratedUserName({
      firstName,
      lastName,
    });

    if (isWayfinderGeneratedName)
      return { ...data, firstName: "", lastName: "" };

    return { ...data, firstName, lastName };
  }, [data]);

  React.useEffect(() => {
    if (wayfinderUser) setUser(wayfinderUser);
  }, [wayfinderUser, setUser]);

  return (
    <WayfinderUserControllerContext.Provider
      value={{
        wayfinderUser,
        updateUser,
        isUpdateSuccess,
        isError: isError || isUpdateError,
        error: error || null,
        isFetching,
        isLoading: isLoading || isUpdateLoading,
      }}
    >
      {children}
    </WayfinderUserControllerContext.Provider>
  );
};
