import * as React from "react";
import { NavLink } from "react-router-dom";
import { Box, styled } from "@mui/system";

import { BrandLogo } from "_global/components/Logo";
import { NavigationSidebarContext } from "../../contexts/NavigationSidebarContext";
import { navRoutes } from "../../util/routes";

const StyledContainer = styled(Box)(() => ({
  minHeight: "200px",
  maxHeight: "225px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexGrow: 1,
  ["@media (max-height: 850px)"]: {
    marginBottom: "-10px",
    minHeight: "180px",
  },
  ["@media (max-height: 780px)"]: {
    marginBottom: "-30px",
  },
}));

type LogoLinkProps = {
  width?: string;
  isBadgeLogo?: boolean;
};

const options = {
  shouldForwardProp: (prop: string) => !["isBadgeLogo"].includes(prop)
};

const LogoLink = styled(NavLink, options)<LogoLinkProps>(({ theme, isBadgeLogo }) => ({
  display: "block",
  width: "100%",
  height: "auto",
  textDecoration: "none",
  padding: isBadgeLogo ? theme.spacing(1.7) : theme.spacing(3),
}));

export const Brand: React.FC = () => {

  const {
    expanded,
    transitionStatus,
  } = React.useContext(NavigationSidebarContext);

  const shouldShowExpandedLogo = React.useMemo(() => (
    expanded || transitionStatus.isExpanding || transitionStatus.isCollapsing
  ), [expanded, transitionStatus]);

  const logo = React.useMemo(() => (
    shouldShowExpandedLogo ? <BrandLogo.Main /> : <BrandLogo.Badge />
  ), [shouldShowExpandedLogo]);

  return (
    <StyledContainer>
      <LogoLink 
        to={navRoutes.dashboard.path} 
        title={`Go to ${navRoutes.dashboard.label}`}
        isBadgeLogo={!shouldShowExpandedLogo}
      >
        {logo}
      </LogoLink>
    </StyledContainer>
  );
};
