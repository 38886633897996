import { TPhaseKey } from "features/ContractedHomes/types";
import { PHASE_INFO, type TPhaseInfo } from "./constants";
import { TPhaseStatuses as PHASE_STATUSES, type TPhaseStatus } from "features/ContractedHomes/types";

export const getPhaseInfo = (phaseKey: TPhaseKey): TPhaseInfo => {
  return PHASE_INFO[phaseKey];
};

export const isPhaseStatusInProgress = (phaseStatus: TPhaseStatus): boolean => (
  phaseStatus === PHASE_STATUSES["In Progress"]
);

export function getPhasePhotosAddedNotificationMessageHTML(phaseName: TPhaseKey) {
  const phaseInfo = getPhaseInfo(phaseName);
  if (!phaseInfo) return "Progress photos have been added!";
  return `Progress photos have been added to ${PHASE_INFO[phaseName].title}!`;
};

export function getNewPhaseStartedNotificationMessageHTML(currentPhaseName: TPhaseKey, prevPhaseName?: TPhaseKey) {
  const phaseInfo = getPhaseInfo(currentPhaseName);
  if (!phaseInfo) return "Construction phase has been updated!";

  const isSinglePhase = !prevPhaseName || prevPhaseName === currentPhaseName || !getPhaseInfo(prevPhaseName);
  if (isSinglePhase) return `Your home has begun <span class="notifications-phase--bold">${PHASE_INFO[currentPhaseName].title}</span>!`;

  const prevPhaseInfo = getPhaseInfo(prevPhaseName);
  return `Your home has moved from <span class="notifications-phase--bold">${prevPhaseInfo.title}</span> to <span class="notifications-phase--bold">${phaseInfo.title}</span>!`;
}
