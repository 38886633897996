import { Button as BaseButton, type ButtonProps as BaseButtonProps } from "@mui/base";
import { Box, styled, Stack } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import {
  LaunchOutlined,
  Delete as DeleteIcon,
  MoreVert,
  Edit as EditIcon,
} from "@mui/icons-material";

import { Tile } from "_global/components/Containers";
import { GridList } from "_global/components/List/GridList/GridList";
import { PrimaryButton } from "_global/components/Buttons/PrimaryButton";
import { themeFonts } from "_global/theme/typography";
import { getLoadDesignHref } from "./utils";
import { UnstyledButton } from "_global/components/Buttons/UnstyledButton";
import type { TSavedDesign } from "features/DesignStudio/types";

import {
  MAIN_CONTENT_PADDING_X_XS,
  MAIN_CONTENT_PADDING_LEFT_SM,
  MAIN_CONTENT_PADDING_RIGHT_SM,
} from "_global/views/Layout/Layout";

type ContainerProps = {
  children: React.ReactNode;
};

const TOTAL_COLS = 50;
const WARNING_INDICATOR_COLSPAN = { xs: 0, sm: 1 };
const DESIGN_TITLE_COLSPAN = { xs: 40, sm: 15 };
const DESKTOP_UPDATED_AT_COLSPAN = { xs: 0, sm: 14 };
const DESKTOP_BUTTON_COLSPAN = { xs: 0, sm: 10 }; // delete & load buttons
const MOBILE_BUTTON_COLSPAN = { xs: 10, sm: 0 }; // mobile menu button

/** MAIN CONTAINER AND IT'S OVERFLOW PARENT ======================================= **/

const OverflowScrollContainer = styled(Box)(({ theme }) => ({
  overflowX: "auto",
  margin: theme.spacing(
    0,
    -MAIN_CONTENT_PADDING_RIGHT_SM,
    0,
    -MAIN_CONTENT_PADDING_LEFT_SM,
  ),
  [theme.breakpoints.down("sm")]: {
    margin: theme.spacing(0, -MAIN_CONTENT_PADDING_X_XS),
  },
}));

const InnerScrollContainer = styled(Box)(({ theme }) => ({
  display: "inline-block",
  verticalAlign: "middle",
  minWidth: "100%",
  paddingBottom: "24px",
  paddingRight: theme.spacing(MAIN_CONTENT_PADDING_RIGHT_SM),
  paddingLeft: theme.spacing(MAIN_CONTENT_PADDING_LEFT_SM),
  [theme.breakpoints.down("sm")]: {
    padding: "0px",
  },
}));

const MainContainer: React.FC<ContainerProps> = (props) => (
  <Box display="flow-root">
    <OverflowScrollContainer>
      <InnerScrollContainer>
        <GridList.OuterContainer
          minWidth={{ xs: "300px", sm: "740px" }}
          maxWidth={{ xs: "100vw", sm: "900px" }}
          sx={{ width: "100%" }}
          {...props}
        />
      </InnerScrollContainer>
    </OverflowScrollContainer>
  </Box>
);

/** ROW WRAPPERS ============================================================= **/

const HeaderRow: React.FC<ContainerProps> = (props) => (
  <GridList.HeaderRowContainer columns={{ xs: TOTAL_COLS }} {...props} />
);

const DataRow: React.FC<React.ComponentProps<typeof GridList.GridRowContainer>> = (props) => (
  <GridList.GridRowContainer columns={{ xs: TOTAL_COLS }} {...props} />
);

/** CELL WRAPPERS =========================================================== **/

const DesktopWarningIndicatorCell: React.FC<ContainerProps> = ({ children }) => (
  <GridList.Cell className="hide-in-small" {...WARNING_INDICATOR_COLSPAN}>
    <Stack position="relative" sx={{ minWidth: "0px" }}>
      {children}
    </Stack>
  </GridList.Cell>
);

const MobileWarningIndicatorCell: React.FC<ContainerProps> = ({ children }) => (
  <GridList.Cell className="hide-in-large" {...WARNING_INDICATOR_COLSPAN}>
    <Stack position="relative" sx={{ minWidth: "0px" }}>
      {children}
    </Stack>
  </GridList.Cell>
);

const DesignTitleCell: React.FC<ContainerProps> = ({ children }) => (
  <GridList.Cell {...DESIGN_TITLE_COLSPAN}>
    <Stack gap="10px" pr="3%" sx={{ minWidth: "0px" }}>
      {children}
    </Stack>
  </GridList.Cell>
);

const DesktopUpdatedAtCell: React.FC<ContainerProps> = ({ children }) => (
  <GridList.Cell className="hide-in-small" justifySelf="end" {...DESKTOP_UPDATED_AT_COLSPAN} pr="40px">
    <Stack flexDirection="row" justifyContent="flex-end">
      {children}
    </Stack>
  </GridList.Cell>
);

const DesktopButtonCell: React.FC<ContainerProps> = ({ children }) => (
  <GridList.Cell className="hide-in-small" justifySelf="end" {...DESKTOP_BUTTON_COLSPAN}>
    <Stack flexDirection="row" justifyContent="flex-end">
      {children}
    </Stack>
  </GridList.Cell>
);

const MobileMenuCell: React.FC<ContainerProps> = ({ children }) => (
  <GridList.Cell className="hide-in-large" justifySelf="end" {...MOBILE_BUTTON_COLSPAN}>
    <Stack flexDirection="row" justifyContent="center">
      {children}
    </Stack>
  </GridList.Cell>
);

/** Exported DataGrid Interface ====================== **/

export const DataGrid = {
  MainContainer,
  HeaderRow,
  DataRow,
  DataRowList: GridList.RowListContainer,
  DesktopWarningIndicatorCell,
  MobileWarningIndicatorCell,
  DesignTitleCell,
  DesktopButtonCell,
  DesktopUpdatedAtCell,
  MobileMenuCell,
};

/** OTHER COMPONENTS ================================================ **/

type ButtonProps = {
  onClick: () => void;
  disabled?: boolean;
}

export const DeleteButton: React.FC<ButtonProps> = (props) => (
  <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: "90%" }}>
    <PrimaryButton
      light
      fullWidth
      aria-label="Delete saved design"
      size="medium"
      icon={DeleteIcon}
      label="Delete"
      {...props}
    />
  </Box>
);

export const MenuButtonMobile: React.FC<BaseButtonProps> = (props) => (
  <Box
    component={BaseButton}
    display="flex"
    alignItems="center"
    justifyContent="center"
    px="0px"
    py="6px"
    sx={{
      backgroundColor: theme => theme.palette.v2.neutral[30],
      border: "0.5px solid transparent",
      borderRadius: "8px",
      transition: "all 0.05s ease-in-out",
      cursor: "pointer",
      "&:hover": {
        color: theme => theme.palette.v2.colorTextLink,
        backgroundColor: theme => theme.palette.v2.primary.surface,
        border: theme => `0.5px solid ${theme.palette.v2.colorTextLink}`,
        boxShadow: theme => theme.palette.v2.shadow1,
      },
    }}
    aria-haspopup="true"
    aria-label="See expanded options"
    {...props}
  >
    <MoreVert fontSize="medium" />
  </Box>
);

type LoadDesignButtonProps = {
  designId: TSavedDesign["id"];
  disabled?: boolean;
};

export const LoadDesignButton: React.FC<LoadDesignButtonProps> = ({ designId, disabled }) => {
  const href = getLoadDesignHref(designId);
  return (
    <a
      href={href}
      aria-label="Load in Design Studio"
      target="_blank"
      style={{
        textDecoration: "unset",
        color: "unset",
        borderRadius: "24px",
        width: "90%",
        pointerEvents: disabled ? "none" : "unset",
      }}
    >
      <PrimaryButton
        light
        disabled={disabled}
        fullWidth
        tabIndex={-1}
        size="medium"
        icon={LaunchOutlined}
        label="Load"
      />
    </a>
  );
};

const StyledEditButton = styled(BaseButton)(({ theme }) => ({
  color: theme.palette.v2.neutral[90],
  marginLeft: "-6px",
  backgroundColor: "transparent",
  border: "0.5px solid transparent",
  cursor: "pointer",
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "24px",
  width: "24px",
  transition: "all 0.05s ease-in-out",
  "&:hover": {
    backgroundColor: theme.palette.v2.primary.surface,
    border: `0.5px solid ${theme.palette.v2.primary.border}`,
    color: theme.palette.v2.primary.main,
    boxShadow: theme.palette.v2.shadow1,
  },
  "svg": {
    fontSize: "14px",
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export const EditButton: React.FC<BaseButtonProps> = (props) => (
  <StyledEditButton
    aria-label="Edit saved design"
    {...props}
  >
    <EditIcon fontSize="inherit" />
  </StyledEditButton>
);

export const WarningIndicatorButton = styled(UnstyledButton)(({ theme }) => ({
  cursor: "pointer",
  borderRadius: "50%",
  minWidth: "24px",
  height: "24px",
  width: "24px",
  transition: "all 0.05s ease-in-out",
  position: "absolute",
  inset: "0px",
  transform: "translate(-100%, -50%)",
  "> .saved-design-warning-indicator": {
    backgroundColor: theme.palette.v2.warning.main,
    borderRadius: "50%",
    height: "10px",
    width: "10px",
    display: "block",
    margin: "auto",
  },
  "&:hover > .saved-design-warning-indicator": {
    backgroundColor: theme.palette.v2.warning.hover,
  },
  // "svg": {
  //   fontSize: "14px",
  // },
}));

export const EmptyState: React.FC = () => (
  <Stack sx={{ ...themeFonts.textLg, color: theme => theme.palette.v2.neutral[90] }}>
    You have no saved designs.
  </Stack>
);

export const LoadingState: React.FC = () => (
  <Tile alignItems="center" justifyContent="center" maxWidth="900px" sx={{ height: "250px", color: "#C9C9C9" }}>
    <CircularProgress color="inherit" />
  </Tile>
);

export const ErrorState: React.FC = () => (
  <Stack sx={{ ...themeFonts.textLg, color: theme => theme.palette.v2.neutral[90] }}>
    We were unable to load your designs.
  </Stack>
);
