import type { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import { getApiClient } from "_global/hooks/useApi";
import type { User } from "_global/types";
import { QUERY_KEYS } from "_global/constants";

type UseGetUserOptions = {
  enabled: boolean;
  token: string | null;
};

export const useGetUser = ({ enabled, token }: UseGetUserOptions) => {
  const client = getApiClient(token || "");

  const { data, isStale, isFetching, isLoading, isError, error } = useQuery<
    User,
    AxiosError
  >({
    enabled,
    queryKey: [QUERY_KEYS.user, "get"],
    queryFn: async () => {
      const response = await client.get<User>("/users");
      return response.data;
    },
  });

  return {
    data,
    isStale,
    isLoading,
    isFetching,
    isError,
    error,
  };
};
