import React from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { useGetSavedDesign } from "../hooks/useGetSavedDesign";
import { useCreateSavedDesign, type TCreateSavedDesignMutationFunc } from "../hooks/useCreateSavedDesign";
import { useUpdateSavedDesign } from "../hooks/useUpdateSavedDesign";
import useTabActive from "features/Auth/hooks/useTabActive";
import type { TSavedDesign } from "../types";
import type { MutationCallbackOptions } from "_global/types";

/**
 * *****************************************************************************************************************************************************
 * ** This controller handles API calls and state management of a Saved Design <type 'TSavedDesign' object>) rendered to the VR360 View. ***********
 * *****************************************************************************************************************************************************
 * 
 * ## USING URL SEARCH PARAMS ("designId") TO FETCH A SAVED DESIGN:
 *  - The Wayfinder SavedDesigns List opens the VR360 view a new tab with a "designId" passed via URL search params when a user selects a saved design.
 *  - The user may also come to this page directly without being launched from the Wayfinder SavedDesigns Page.
 *  
 *  - RENDERING A NEW DESIGN (UNSAVED):
 *    - a NEW DESIGN (UNSAVED) is essentially the default state of the VR360 iframe app, with no active `savedDesign` record associated with it.
 *      - it's initial render will be the VR360 iframe app default initial render.
 *      - the user may interact with it and change it's internal state.
 *      - It becomes a SAVED DESIGN when the user clicks save and it's state is preserved as a `savedDesign` record in the database.
 *    - No "designId" in the URL search params indicates the user is creating a new unsaved design.
 *    - An unauthenticated user will be working with a new unsaved design regardless of "designId" URL params.
 *    - ### UPCOMING FEATURE ###:
 *      - in some scenarios, we intend to cache the unsaved design in local storage so the user can be prompted to continue working on it if they navigate away from the page (ie, during a sign-in flow).
 * 
 *  - RENDERING A SAVED DESIGN:
 *    - The source of truth for a rendered `savedDesign` is the `useGetSavedDesign` hook --> "savedDesign" record
 *      - search triggered via the "designId" in the URL search params.
 *    - the absence of a "savedDesign" record indicates the user is creating a NEW DESIGN (UNSAVED) or an error occurred
 *    - ERROR STATES possible from "designId" URL params
 *      - the user must be authenticated & should be the owner of the design
 *      - the designId must be valid & must exist in the database.  The design may have been deleted in another tab.
 *      - In an error state, an error toast should launch, however the user may still see the default NEW DESIGN (UNSAVED) view in the VR360 iframe app (unless we prevent it and prompt them to create a new design (possible post-MVP feature?)).
 *   
 *  - UPDATING SAVED DESIGN
 *     - the user may update the rendered `savedDesign` record through save actions in the interface (ie, after making changes).
 *  
 *  - CREATING A NEW DESIGN FROM SAVED DESIGN
 *     - the user may create a new design as a fork of the saved design by clicking the "New Design" button in the VR360 View.  The newly created design will become the new RENDERED SAVED DESIGN (see section on updating source of truth below).
 * 
 *  - CREATING A NEW SAVED DESIGN & UPDATING THE SOURCE OF TRUTH:
 *    - the `useCreateDesign` mutation returns the record of the newly created design
 *       - the "designId" URL params are updated to the `newSavedDesign` id
 *         - SOURCE OF TRUTH UPDATED: the new URL params trigger `useGetSavedDesign` to fetch the newly created design from the database
 *           - this prevents stale state bugs & allows the user to refresh the page and render same saved design
 * 
 * *****************************************************************************************************************************************************
 */

type TUpdateSavedDesignOptions = {
  configId?: TSavedDesign["configId"];
  name?: TSavedDesign["name"];
};

export type VR360ViewProps = {
  savedDesign?: TSavedDesign;
  isError: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isAuthed: boolean;
  isInitialLoading: boolean;
  sharedConfigId: string | null;
  updateSavedDesign: (options: TUpdateSavedDesignOptions, callbacks?: MutationCallbackOptions) => void;
  createSavedDesign: TCreateSavedDesignMutationFunc;
};

type VR360DesignControllerProps = {
  component: React.FC<VR360ViewProps>;
};

export const VR360DesignController: React.FC<VR360DesignControllerProps> = ({
  component: Component,
}) => {
  const { user } = useGlobalContext();
  const { isAuthenticated, getAccessTokenSilently, ...auth0 } = useAuth0();
  const [token, setToken] = React.useState<string | null>(null);

  const isTabActive = useTabActive();

  const [searchParams, setSearchParams] = useSearchParams();

  const [designId, sharedConfigId] = React.useMemo(() => {
    const id = searchParams.get("designId") || null;
    const paramsConfigId = searchParams.get("configId") || null;
    return  [id ? parseInt(id) : null, paramsConfigId];
  }, [searchParams]);

  // Refresh stale auth0 state if user logs out in another tab
  const shouldGetFreshToken = isAuthenticated && isTabActive;
  const shouldWaitForToken = !!designId && isAuthenticated && !token; // TODO: this line fixes a bug introduced in commit c17e131; it prevents the initial render of the VR360, it won't render a saved design because it uses the DEFAULT_CONFIG_ID while the async token check is happening.  
  const shouldFetchDesignById = isAuthenticated && !auth0.isLoading && !!user && !!token && !!designId;

  React.useEffect(() => {
      if (!shouldGetFreshToken) return;
    
      setToken(null);
  
      const fetchToken = async () => {
        try {
          const tkn = await getAccessTokenSilently();
          setToken(tkn);
        } catch (error) { /* empty */ }
      };
      fetchToken();
    }, [shouldGetFreshToken, getAccessTokenSilently]);

  // FETCH SAVED DESIGN
  const {
    data: savedDesign,
    isError,
    isLoading: isSavedDesignLoading,
    isFetching,
  } = useGetSavedDesign({
    id: designId,
    token,
    enabled: shouldFetchDesignById
  });

  // UPDATE SAVED DESIGN
  const {
    mutate: updateSavedDesign,
    ...updateFn
  } = useUpdateSavedDesign();

  const handleUpdateSavedDesign = React.useCallback(
  (
    { configId = "", name = "" }: TUpdateSavedDesignOptions,
    callbacks?: MutationCallbackOptions,
  ) => {
    if (!savedDesign) return;
    updateSavedDesign(
      {
        id: savedDesign.id,
        name: name || savedDesign.name,
        configId: configId || savedDesign.configId,
      },
      callbacks,
    );
  }, [savedDesign, updateSavedDesign]);

  // CREATE SAVED DESIGN
  const {
    data: newSavedDesign,
    mutate: createSavedDesign,
    ...createFn
  } = useCreateSavedDesign();

  const didCreateNewDesign = React.useMemo(() => {
    return !!newSavedDesign?.data.id;
  }, [createFn.isSuccess, newSavedDesign]);

  React.useEffect(() => {
    if (didCreateNewDesign) {
      const newDesignId = newSavedDesign?.data.id;
      if (newDesignId) setSearchParams({ designId: newDesignId.toString() });
    }
  }, [didCreateNewDesign, newSavedDesign, setSearchParams]);

  React.useEffect(() => {
    if (!isAuthenticated) {
      if (searchParams.has("designId") && newSavedDesign?.data?.id) {
        newSavedDesign.data.id = undefined;
        searchParams.delete("designId");
        setSearchParams(searchParams);
        window.location.reload();
      }
    }
  }, [isAuthenticated]);

  const isLoading = isSavedDesignLoading || createFn.isLoading || updateFn.isLoading;

  // EXPORT CONTROLLER PROPS
  return (
    <Component
      savedDesign={savedDesign}
      isAuthed={isAuthenticated}
      isError={isError}
      isLoading={isLoading}
      isFetching={isFetching}
      isInitialLoading={isSavedDesignLoading || shouldWaitForToken}
      sharedConfigId={sharedConfigId}
      updateSavedDesign={handleUpdateSavedDesign}
      createSavedDesign={createSavedDesign}
    />
  );
};
