import { useMatch, useNavigate } from "react-router-dom";
import type { StackTypeMap } from "@mui/system";
import { StyledTab, StyledTabContainer } from "./Tabs.elements";
import { ACTIVE_CLASS } from "./constants";

export type TTabProps = {
  onClick?: () => void;
  label: string | React.ReactNode;
  isActive?: boolean;
  disabled?: boolean;
  ariaLabel?: string;
};

/**  Button with onClick callback */
const Tab: React.FC<TTabProps> = ({
  onClick,
  label,
  ariaLabel = label,
  isActive = false,
  disabled = false,
}) => {
  return (
    <StyledTab
      role="tab"
      aria-label={ariaLabel}
      aria-selected={isActive ? "true" : "false"}
      className={isActive ? ACTIVE_CLASS : ""}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </StyledTab>
  );
};

export type TNavTabProps = {
  path: string;
  label: string | React.ReactNode;
  ariaLabel?: string;
  disabled?: boolean;
};

/**  Button with react-router logic */
const NavTab: React.FC<TNavTabProps> = ({
  path,
  label,
  ariaLabel,
  disabled = false,
}) => {
  const match = useMatch(path);
  const navigate = useNavigate();

  const generatedAriaLabel = ariaLabel || `Go to ${typeof label === "string" ? label : "tab"}`; // if a React.Node is passed instead of string, use "tab" as a fallback

  const onClick = () => {
    if (!disabled) navigate(path);
  };

  return (
    <StyledTab
      role="tab"
      aria-label={generatedAriaLabel}
      aria-selected={match ? "true" : "false"}
      className={match ? ACTIVE_CLASS : ""}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </StyledTab>
  );
};

type TTabContainerProps = StackTypeMap["props"] & {
  children: React.ReactNode;
  ariaLabel?: string;
};

const TabListContainer: React.FC<TTabContainerProps> = ({
  children,
  ariaLabel = "Tab options",
  ...props
}) => {
  return (
    <StyledTabContainer role="tablist" aria-label={ariaLabel} {...props}>
      {children}
    </StyledTabContainer>
  );
};

export const Tabs = {
  TabListContainer,
  Tab,
  NavTab,
};
