import * as React from "react";
import { Box, Stack, styled } from "@mui/system";
import {
  Menu,
  menuItemClasses,
  MenuItem as BaseMenuItem,
} from "@mui/base";
import { ViewSavedDesignsButton } from "./StudioHeader.elements";
import { EditDesignNameForm } from "../EditDesignName/EditDesignNameForm";
import { BottomDrawerModal } from "_global/components/Drawers/BottomDrawer";
import { ReactComponent as ArrowRightIcon } from "_global/assets/icons/arrow-right-link-icon.svg";
import { themeFonts } from "_global/theme/typography";
import { MENU_ITEMS_DRAWER, MENU_ITEM_IDS } from "../constants";
import { formatDateTimeDisplay, getSavedDesignMenuItems } from "../utils";
import type { VR360ComponentProps } from "features/DesignStudio/VR360IframeApp";

export const EXPANDED_OPTIONS_DRAWER_ID = "expanded-options-drawer";
const PAHLISCH_SITE_URL = process.env.REACT_APP_SITE_URL;

type Props = {
  open: boolean;
  onClose: () => void;
} & VR360ComponentProps;

export const ExpandedOptionsBottomDrawer: React.FC<Props> = (props) => {
  const { savedDesign, isAuthed, open, onClose } = props;
  const [isEditForm, setIsEditForm] = React.useState<boolean>(false);
  const showEditForm = () => setIsEditForm(true);
  const hideEditForm = () => setIsEditForm(false);

  const onSave = (name: string) => {
    props.onUpdateDesignName(name, { onSuccess: hideEditForm });
  };

  const menuItems = React.useMemo(() => getSavedDesignMenuItems(props, MENU_ITEMS_DRAWER), [props]);

  return (
    <BottomDrawerModal
      id={EXPANDED_OPTIONS_DRAWER_ID}
      title="Options"
      hideTitle
      open={open}
      onClose={onClose}
    >
      {(isEditForm && !!savedDesign) ? (
        <Stack gap="28px" py="30px">
          <StyledModalTitle>Edit Design Name</StyledModalTitle>
          <EditDesignNameForm
            savedDesign={savedDesign}
            isLoading={props.isLoading}
            onSave={onSave}
            onCancel={hideEditForm}
            onClickBack={hideEditForm}
          />
        </Stack>
      ) : (
        <Stack gap="28px" py="30px">
          <StyledModalTitle>Options</StyledModalTitle>
          {!!savedDesign && (
            <StyledDesignTitle>
              {savedDesign.name} | <StyledDate>Last saved: {formatDateTimeDisplay(savedDesign.updated_at)}</StyledDate>
            </StyledDesignTitle>
          )}
          <Menu slots={{ listbox: Listbox }}>
            {menuItems.map(({label, id, icon: Icon, ...menuProps}) => {
              const shouldCloseDrawer = id !== MENU_ITEM_IDS.manageDesign;
              const shouldShowEditForm = id === MENU_ITEM_IDS.manageDesign;
              return (
                <MenuItem
                  key={id}
                  {...menuProps}
                  onClick={() => {
                    menuProps.onClick();
                    if (shouldCloseDrawer) props.onClose();
                    if (shouldShowEditForm) showEditForm();
                  }}
                >
                  {!!Icon && <Icon />} {label}
                </MenuItem>
              );
            })}
          </Menu>
          <ViewSavedDesignsButton isDrawer disabled={!isAuthed} />
          <StyledLink href={PAHLISCH_SITE_URL} aria-label="Go to Pahlisch Homes site" target="_blank">
            Pahlisch website <ArrowRightIcon height="22px" width="22px" />
          </StyledLink>
        </Stack>
      )}
    </BottomDrawerModal>
  );
};

const StyledModalTitle = styled(Box)(({ theme }) => ({
  ...themeFonts.textLgSemibold,
  color: theme.palette.v2.neutral[90],
}));

const StyledDesignTitle = styled(Box)(({ theme }) => ({
  flex: 1,
  display: "block",
  alignItems: "center",
  gap: "4px",
  ...themeFonts.textMdSemibold,
  letterSpacing: "0.25px",
  color: theme.palette.black,
  overflow: "hidden",
  textOverflow: "ellipsis",
  minWidth: "0px",
  whiteSpace: "nowrap",
}));

const StyledDate = styled("span")(() => ({
  ...themeFonts.textSmSemibold,
}));

const Listbox = styled("ul")(() => ({
  boxSizing: "border-box",
  minWidth: "200px",
  overflow: "auto",
  padding: "0",
  margin: "0",
}));

const MenuItem = styled(BaseMenuItem)(({ theme }) => ({
  color: theme.palette.v2.neutral[90],
  ...themeFonts.textLgSemibold,
  listStyle: "none",
  padding: "24px 24px",
  margin: "0px -18px",
  userSelect: "none",
  display: "flex",
  alignItems: "center",
  lineHeight: 1,
  gap: "12px",
  cursor: "pointer",
  borderBottom: `1px solid ${theme.palette.v2.neutral[40]}`,

  [`&.${menuItemClasses.focusVisible}`]: {
    outline: `2px solid ${theme.palette.v2.primary.border}`,
    outlineOffset: "-3px",
    backgroundColor: theme.palette.v2.neutral[10],
    color: theme.palette.v2.neutral[90],
  },

  [`&.${menuItemClasses.disabled}`]: {
    cursor: "not-allowed",
    color: theme.palette.v2.neutral[60],
  },

  [`&:hover:not(.${menuItemClasses.disabled})`]: {
    backgroundColor: theme.palette.v2.primary.surface,
    color: theme.palette.v2.primary.pressed,
  },
}));

const StyledLink = styled("a")(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.v2.primary.main,
  display: "flex",
  gap: "8px",
  alignItems: "center",
  fontSize: "14px",
  fontWeight: 700,
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    color: theme.palette.v2.primary.hover,
    gap: "9px",
    "& svg": {
      fill: theme.palette.v2.primary.hover,
    },
  },
  "svg": {
    fill: theme.palette.primary.main,
  },
}));
