import React from "react";
import { Box } from "@mui/system";
import { Brand } from "./Brand";
import { SavedDesignTitle } from "./SavedDesignTitle";
import { SavedDesignMenuButton } from "./SavedDesignMenuButton";
import {
  HeaderContainer,
  HeaderRightDesktop,
  HeaderRightMobile,
  StudioHeaderHelpButton,
  ViewSavedDesignsButton,
} from "./StudioHeader.elements";
import { ExpandedOptionsButton } from "./ExpandedOptionsButton";
import type { VR360ComponentProps } from "features/DesignStudio/VR360IframeApp";

type Props = VR360ComponentProps & {
    onClickHelpButton: () => void;
};

export const StudioHeader: React.FC<Props> = ({
    onClickHelpButton,
    ...props
}) => {
    const { isAuthed } = props;
    const headerProps = {
        ...props,
        savedDesign: isAuthed ? props.savedDesign : undefined, // Hide saved design controls if not authed (ie, logged out in another tab)
    };

    return (
        <HeaderContainer component="header">
            <Brand />
            <HeaderRightDesktop>
                <SavedDesignTitle {...headerProps} />
                <Box display="inline-flex" flexShrink={0} alignItems="center" gap="16px" sx={{ minWidth: "0px" }}>
                    <SavedDesignMenuButton {...headerProps} />
                    <ViewSavedDesignsButton disabled={!isAuthed} />
                </Box>
            </HeaderRightDesktop>
            <HeaderRightMobile>
                <ExpandedOptionsButton {...headerProps} />
            </HeaderRightMobile>
            <StudioHeaderHelpButton onClick={onClickHelpButton} />
        </HeaderContainer>
    );
};
