import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import { AppLayout } from "./views/Layout/AppLayout";
import { CustomerCare } from "./views/CustomerCare";
import { Dashboard } from "./views/Dashboard";
import { DesignStudio } from "./views/DesignStudio";
import { DigitalWarrantyManualFullPage } from "features/CustomerCare/DigitalWarranty";
import { Documents } from "./views/Documents";
import { Frame } from "./views/Frame";
import { Login } from "./views/Login/Login";
import { Onboarding } from "./views/Onboarding";
import { Profile } from "./views/Profile";
import { Team } from "./views/Team";
import { Timeline } from "./views/Timeline";
import { NotFoundPage } from "./views/NotFoundPage";

import App from "./App";
import { ErrorDefault } from "./ErrorDefault";
import { ProtectedRoute } from "features/Auth/ProtectedRoute";
import { Logout } from "features/Auth/Logout";

import { routes } from "./features/Navigation/util/routes";
import { GlobalContextProvider } from "_global/contexts/GlobalContext";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

/**
 * Routes, for the most part, are paths to views with some exceptions
 */
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorDefault />,
    children: [
      {
        path: routes.login.path,
        element: <Login />,
      },
      {
        path: routes.logout.path,
        element: <Logout />,
      },
      {
        path: routes.error.path,
        element: <ErrorDefault />,
      },
      {
        path: routes.designStudio.publicPath,
        element: <Frame />,
      },
      {
        path: routes.onboarding.path,
        element: <ProtectedRoute component={Onboarding} />,
      },
      {
        path: routes.digitalWarrantyExpandedView.path,
        element: <ProtectedRoute component={DigitalWarrantyManualFullPage} />,
      },
      {
        path: "/",
        element: <ProtectedRoute component={AppLayout} />,
        children: [
          {
            path: routes.dashboard.path,
            element: <ProtectedRoute component={Dashboard} />,
          },
          {
            path: routes.timeline.path,
            element: <ProtectedRoute component={Timeline} />,
          },
          {
            path: routes.team.path,
            element: <ProtectedRoute component={Team} />,
          },
          {
            path: routes.customerCare.path,
            element: <ProtectedRoute component={CustomerCare.Root} />,
            children: [
              {
                index: true,
                element: <CustomerCare.FAQs />,
              },
              // {
              //   index: true,
              //   element: <CustomerCare.DigitalWarrantyManual />,
              // },
              {
                path: routes.customerCare.subroutes.faqs.path,
                element: <CustomerCare.FAQs />,
              },
            ],
          },
          {
            path: routes.designStudio.path,
            element: <ProtectedRoute component={DesignStudio} />,
          },
          {
            path: routes.documents.path,
            element: <ProtectedRoute component={Documents} />,
          },
          {
            path: routes.profile.path,
            element: <ProtectedRoute component={Profile.Root} />,
            children: [
              {
                index: true,
                element: <Profile.Main />,
              },
              {
                path: routes.profile.subroutes.savedHomes.path,
                element: <Profile.SavedHomes />,
              },
              {
                path: routes.profile.subroutes.notifications.path,
                element: <Profile.Notifications />,
              },
            ],
          },
          {
            path: "*",
            element: <ProtectedRoute component={NotFoundPage} />,
          },
        ],
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <GlobalContextProvider>
      <RouterProvider router={router} />
    </GlobalContextProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
