import React from "react";
import { Box, Stack, styled } from "@mui/system";

import {
  Brand,
  PrimaryNavMenu,
  ToggleExpandButton,
} from "./components/Sidebar";

import {
  SIDEBAR_WIDTH,
  TRANSITION_DURATION,
  SIDEBAR_OVERFLOW_X_BUFFER,
} from "./util/constants";

import {
  NavigationSidebarContext,
  NavigationSidebarContextProvider
} from "features/Navigation/contexts/NavigationSidebarContext";

const transition = `width ${TRANSITION_DURATION}ms cubic-bezier(0.4, 0, 0.6, 1) 0ms`;

const RelativeWrapper = styled(Box)(() => ({
  position: "relative",
  overflow: "visible",
  transition, 
}));

const OverflowWrapper = styled(Box)(() => ({
  height: "100vh",
  overflowY: "auto",
  transition,
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  minHeight: "100%",
  backgroundColor: theme.palette.v2.neutral[20],
  transition,
}));

const SidebarContent = () => {
  const { expanded } = React.useContext(NavigationSidebarContext);

  const width = React.useMemo(() => (
    expanded ? SIDEBAR_WIDTH.expanded : SIDEBAR_WIDTH.collapsed
  ), [expanded]);

  return (
    <RelativeWrapper width={width}>
      <ToggleExpandButton />
      <OverflowWrapper minWidth={width + SIDEBAR_OVERFLOW_X_BUFFER}>
        <StyledStack width={width} height="auto">
          <Brand />
          <PrimaryNavMenu />
        </StyledStack>
      </OverflowWrapper>
    </RelativeWrapper>
  );
};

export const NavigationSidebar = () => (
  <NavigationSidebarContextProvider>
    <SidebarContent />
  </NavigationSidebarContextProvider>
);
