import React from "react";
import type { TDocumentsViewProps } from "../controllers/DocumentsController";
import type { TDocumentFilterCategory } from "../types";
import { getFlatDocuments, shouldHideCustomerCareDocuments } from "../utils";
import { useGlobalContext } from "_global/contexts/GlobalContext";

export const useDocumentFilters = ({ documentsData }: { documentsData: TDocumentsViewProps["documentsData"] }) => {
  const { user } = useGlobalContext();
  
  const [selectedCategory, setSelectedCategory] = React.useState<TDocumentFilterCategory | null>(null);
  const [searchQuery, setSearchQuery] = React.useState<string>("");

  const documents = React.useMemo(() => getFlatDocuments(documentsData), [documentsData]);
  
  const documentsFilteredByCategory = React.useMemo(() => {
    if (!selectedCategory) return documents;
    return documents.filter((document) => document.category === selectedCategory);
  }, [documents, selectedCategory]);

  const filteredDocuments = React.useMemo(() => {
    const q = searchQuery.trim().toLowerCase();
    if (!q) return documentsFilteredByCategory;
    return documentsFilteredByCategory.filter((document) => document.name?.toLowerCase().includes(q));
  }, [documentsFilteredByCategory, searchQuery, selectedCategory]);

  const clearFilters = React.useCallback(() => {
    setSelectedCategory(null);
    setSearchQuery("");
  }, []);

  const shouldHideFilters = React.useMemo(() => shouldHideCustomerCareDocuments(user), [user]);
  
  return {
    clearFilters,
    searchQuery,
    setSearchQuery,
    selectedCategory,
    setSelectedCategory,
    documents,
    filteredDocuments,
    shouldHideFilters,
  };
};
