import React from "react";
import { Outlet } from "react-router-dom";
import { Box, Stack } from "@mui/system";
import { CustomerCarePageTabs } from "./components/CustomerCarePageTabs";
import { PageHeader } from "features/PageHeader";
import { ServiceRequestFormDrawer,HomeownerServiceRequestCard } from "features/CustomerCare";

export const CustomerCarePageRoot = () => {
  const [isServiceRequestDrawerOpen, setIsServiceRequestDrawerOpen] = React.useState(false);
  const openServiceRequestDrawer = () => setIsServiceRequestDrawerOpen(true);
  const closeServiceRequestDrawer = () => setIsServiceRequestDrawerOpen(false);

  return (
    <Stack gap="40px">
      <PageHeader title="Customer Care" />
      <CustomerCarePageTabs />
      <Box
        p={{ xs: "10px 0px 40px", sm: "0px 0px 40px 8px" }}
        maxWidth={1450}
        display="flex"
        justifyContent="space-between"
        flexWrap={{ xs: "wrap", lg: "nowrap" }}
        gap="40px 40px"
      >
        <Box display="flow-root" flexGrow={1} minWidth={0}>
          <Stack>
            <Outlet />
          </Stack>
        </Box>
        <Box m={{ xs: "auto", sm: "0px" }}>
          <HomeownerServiceRequestCard openServiceRequestDrawer={openServiceRequestDrawer} />
        </Box>
      </Box>
      <ServiceRequestFormDrawer open={isServiceRequestDrawerOpen} onClose={closeServiceRequestDrawer} />
    </Stack>
  );
};
