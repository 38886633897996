import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useSearchParams } from "react-router-dom";
import { LogoHeader } from "./components/LogoHeader";
import { SignUp } from "./components/SignUp";
import { LoginButton } from "./components/LoginButton";
import { ImageContainer } from "./components/ImageContainer";
import { FormContainer, PageContainer } from "./Login.elements";
import useTabActive from "features/Auth/hooks/useTabActive";
import { useLogin, type IUseLoginParams } from "features/Auth/hooks/useLogin";
import { trackGAEvent } from "utils/google-analytics/google-analytics";
import { DESIGN_ROUTE } from "features/DesignStudio/constants";
import { HOMES_FOR_SALE, SAVED_HOMES_ROUTE } from "./constants";

export const Login = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");
  const isTabActive = useTabActive();
  const { getAccessTokenSilently } = useAuth0();
  
  const isRequestFromHomesForSale = from && from === HOMES_FOR_SALE;
  const isRequestFromDesign = location.state?.from && location.state?.from === DESIGN_ROUTE;

  const useLoginOptions: IUseLoginParams = React.useMemo(() => {
    if (isRequestFromDesign) return { returnTo: DESIGN_ROUTE };
    if (isRequestFromHomesForSale) return { returnTo: SAVED_HOMES_ROUTE };
    return {};
  }, [isRequestFromDesign, isRequestFromHomesForSale]);

  const { handleLogin } = useLogin(useLoginOptions);
  const { handleSignUp } = useLogin();

  const signup = React.useCallback(() => {
    handleSignUp();
    trackGAEvent({
      category: "Wayfinder",
      action: "login",
      label: "new_signup",
    });
  }, [handleSignUp]);

  const login = React.useCallback(() => {
    handleLogin();
    if (isRequestFromDesign) {
      trackGAEvent({
        category: "Wayfinder",
        action: "login",
        label: "design_studio_visit",
      });
    } else if (isRequestFromHomesForSale) {
      trackGAEvent({
        category: "Wayfinder",
        action: "login",
        label: "homes_for_sale_visit"
      });
    } else {
      trackGAEvent({
        category: "Wayfinder",
        action: "login",
        label: "return_visit",
      });
    }
  }, [handleLogin, isRequestFromDesign, isRequestFromHomesForSale]);

  React.useEffect(() => {
    const checkForExistingToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) login(); // auto-login if token exists
      } catch (err) {  /* empty */ }
    };

    checkForExistingToken();
  }, [isTabActive]);

  return (
    <PageContainer>
      {/* left column with backgroundImage & wavy border */}
      <ImageContainer />
      {/* right column with login form */}
      <FormContainer>
        <LogoHeader />
        <LoginButton onClick={login} />
        <SignUp onClickSignUp={signup} />
      </FormContainer>
    </PageContainer>
  );
};
