import { Box } from "@mui/system";
import { Tabs, type TNavTabProps } from "_global/components/Tabs";
import { routes } from "features/Navigation/util/routes";

const FAQTabLabel = () => (
  <>
    <Box
      component="abbr"
      title="Frequently Asked Questions"
      sx={{ display: { xs: "inline", sm: "none" }, textDecoration: "none" }}
    >
      FAQs
    </Box>
    <Box
      component="span"
      sx={{ display: { xs: "none", sm: "inline" } }}
    >
      Frequently Asked Questions
    </Box>
  </>
);

const NAV_TABS: TNavTabProps[] = [
  // {
  //   label: "Digital Warranty Manual",
  //   path: routes.customerCare.path,
  // },
  {
    label: <FAQTabLabel />,
    ariaLabel: "Go to Frequently Asked Questions",
    path: routes.customerCare.subroutes.faqs.path,
  },
];

export const CustomerCarePageTabs = () => (
  <Tabs.TabListContainer>
    {NAV_TABS.map((navTabProps) => (
      <Tabs.NavTab key={navTabProps.path} {...navTabProps} />
    ))}
  </Tabs.TabListContainer>
);
