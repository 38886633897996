import React from "react";
import { Stack, Box, styled } from "@mui/system";
import { LoadingSkeleton } from "_global/components/Skeleton/LoadingSkeleton";

const CardContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "inline-flex",
  flexDirection: "column",
  padding: "26px 22px",
  borderRadius: "20px",
  width: "100%",
  border: `1px solid ${theme.palette.v2.neutral[40]}`,
  gap: "28px",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
  },
}));

const StyledTitle = styled(Box)(() => ({
  fontSize: "18px",
  color: "#000",
  letterSpacing: "0.1px",
  margin: 0,
  fontWeight: 400,
}));

const StyledBody = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  fontSize: "16px",
  lineHeight: "26px",
  fontWeight: 300,
  gap: "8px",
  "& span": {
    fontWeight: "300!important", // !important is used to override the 'style="400px"' in the span tag from the API feed
  },
  "& strong": {
    fontWeight: 500,
  },
}));

const HeaderContainer = ({ children, isLoading = false }: React.PropsWithChildren<{ isLoading?: boolean }>) => (
  <Stack gap="16px">
    {children}
    <Box
      aria-hidden="true"
      width="36%"
      borderBottom={theme => `3px solid ${theme.palette.v2.primary.main}`}
      sx={{ opacity: isLoading ? 0.3 : 1 }}
    />
  </Stack>
);

const Header = ({ title }: { title: string }) => (
  <HeaderContainer>
    <StyledTitle component="h2">{title}</StyledTitle>
  </HeaderContainer>
);

const CardSkeleton = () => (
  <CardContainer sx={{ opacity: 0.8 }}>
    <HeaderContainer isLoading>
      <LoadingSkeleton height={22} borderRadius="12px" />
    </HeaderContainer>
    <LoadingSkeleton height={150} borderRadius="12px" />
  </CardContainer>
);

export const Card = {
  Container: CardContainer,
  Header,
  Body: StyledBody,
  LoadingSkeleton: CardSkeleton,
};
