import { Box } from "@mui/system";
import { FAQCard } from "../FAQCard/FAQCard";
import { ListGrid, FAQCardListSkeleton } from "./FAQCardList.elements";
import type { TFAQData } from "../../types";

type Props = {
  faqList: TFAQData[];
};

export const FAQCardList = ({ faqList }: Props) => {
  return (
    <ListGrid component="ul">
      {faqList.map((faq) => (
        <Box component="li" key={faq.id}>
          <FAQCard
            title={faq.questionHTML}
            description={faq.answerHTML}
          />
        </Box>
      ))}
    </ListGrid>
  );
};

export { FAQCardListSkeleton };
