import { Box, Stack } from "@mui/system";
import { LoadingSpinner } from "_global/components/Progress/LoadingSpinners";
import { Typography } from "_global/components/Typography/Typography";

const MARKETING_CUSTOMER_CARE_URL = "https://pahlischhomes.com/homeowner/customer-care";

export const ErrorDisplay = () => (
  <Stack justifyContent="center" alignItems="center" textAlign="center" p="20px" gap="20px">
    <Typography.Body>
      There was an error loading the form.
    </Typography.Body>
    <Box>
      <Box
        component="a"
        href={MARKETING_CUSTOMER_CARE_URL} 
        target="_blank"
        sx={{
          color: theme => theme.palette.v2.colorTextLink,
          "&:hover": {
            color: theme => theme.palette.v2.primary.hover,
          },
          textDecoration: "underline",
          textUnderlineOffset: "2px",
          lineHeight: "1.5",
        }}
      >
        Please visit our Customer Care page on Pahlisch Homes to submit a request.
      </Box>
    </Box>
  </Stack>
);

export const LoadingDisplay = () => (
  <Box
    sx={{
      position: "absolute",
      inset: 0,
      width: "460px",
      background: theme => theme.palette.v2.neutral[10]
    }}
    height="90vh"
    display="flex"
    justifyContent="center"
  >
    <LoadingSpinner />
  </Box>
);
