import React from "react";
import { Box, Stack, styled } from "@mui/system";
import { useWindowResize } from "_global/hooks/useWindowResize";
import { BottomDrawerModal } from "_global/components/Drawers/BottomDrawer";
import { RightDrawerModal } from "_global/components/Drawers/RightDrawer/RightDrawer";
import { themeFonts } from "_global/theme/typography";

type Props = {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
};

export const SavedDesignInfoDrawer: React.FC<Props> = (props) => {
  const { open, onClose, title, description } = props;
  const { windowSize, isTablet, isDesktop } = useWindowResize();
  const isMobile = !isTablet && !isDesktop;

  // Modal renders as a BottomDrawer on mobile, and a RightDrawer on tablet/desktop
  const DrawerModal = React.useMemo(() => (
    isMobile ? BottomDrawerModal : RightDrawerModal
  ), [isMobile]);
  
  if (!windowSize.width) return null; // prevent mounting before windowSize is available

  const isInfoEmpty = !title && !description; // precautionary check, should never happen

  return (
    <DrawerModal
      id="saved-design-info-drawer"
      title="Saved Design Information"
      // hideTitle
      open={open}
      onClose={onClose}
    >
      <Stack gap="20px" pt="10px" pb="40px">
        <StyledTitle component="h4">{title}</StyledTitle>
        <StyledDescription component="p">{description}</StyledDescription>
        {isInfoEmpty && (
          <StyledDescription>
            No help info available for this design.
          </StyledDescription>
        )}
      </Stack>
    </DrawerModal>
  );
};

const StyledTitle = styled(Box)(({ theme }) => ({
  margin: 0,
  color: theme.palette.black,
  ...themeFonts.textLgSemibold,
}));

const StyledDescription = styled(Box)(({ theme }) => ({
  color: theme.palette.black,
  ...themeFonts.textLReading,
}));
