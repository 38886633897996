import type { User } from "_global/types";
import type { TDocumentsViewProps } from "./controllers/DocumentsController";
import type { TDocusignEnvelope, TFlatDocument, TCustomerCareDocumentFolder, TDocumentHelpInfo, TDocumentsResponseDTO } from "./types";
import { DOCUMENT_HELP_INFO_MAP } from "./constants";

const getFlattenedDocumentFromEmptyEnvelope = (envelope: TDocusignEnvelope): TFlatDocument => {
  // if `envelope["documents"]` array is empty, derive a flat document from the parent envelope
  // uncertain if this is ever an actual condition, this is a precaution
  return {
    ...envelope,
    documentUri: envelope.documentsUri,
    uid: envelope.documentsUri,
    documentId: "",
    category: "purchase",
    docType: "envelope",
  };
};

const flattenDocumentEnvelope = (envelope: TDocusignEnvelope): TFlatDocument[] => {
  const { documents, documentsUri, ...rest } = envelope;

  if (!documents.length) return [getFlattenedDocumentFromEmptyEnvelope(envelope)];

  return documents.map((doc, idx) => {
    const { docType, documentId, name } = doc;
    
    // default to array index if no documentId or docType is provided - will be used as "key" prop in loops
    const uidPrefix = documentId || docType || name || idx; // uncertain if this is a valid assumption or what values are guaranteed in the DTO
    const uid = `${uidPrefix}__${envelope.documentsUri}`;
  
    return {
      ...rest,
      ...doc,
      uid,
      category: "purchase",
      name: name || envelope.name || "Untitled Document",
      documentId: documentId || "",
      documentUri: documentsUri,
      docType: docType || "",
    };
  });
};

const flattenCustomerCareFolder = (folder: TCustomerCareDocumentFolder): TFlatDocument[] => {
  if (!folder.files?.length) return [];

  return folder.files.map((file, idx) => {
    const { fileId, downloadUri, name } = file;
    const uid = `${file.fileId}_${folder.name}__${idx}`; // uncertain if there's any risk of fileId collision, appending folder name as a precaution
  
    return {
      ...file,
      uid,
      documentUri: downloadUri,
      name: name || "Untitled Document",
      documentId: fileId || "",
      category: "nhd",
      docType: "customerCare", // TODO: docType is used to populate Help Info tooltips, but that structure might need adjustment in near future as real document data is evaluated
      updated_at: "", // not showing updated_at for customer care docs currently
    };
  });
};

// NORMALIZES DOCUMENT DATA FOR DISPLAY IN DOCUMENTS TABLE
export const getFlatDocuments = (data: TDocumentsResponseDTO | null): TFlatDocument[] => {
  if (!data) return [];
  const { docusign = [], customerCare = [] } = data;
  const docusignDocList = docusign.flatMap(flattenDocumentEnvelope);
  const customerCareDocList = customerCare.flatMap(flattenCustomerCareFolder);
  return [...docusignDocList, ...customerCareDocList];
};

export const formatDateDisplay = (date: string) => {
  if (!date) return "";
  const d = new Date(date);
  const isInvalidateDate = isNaN(d.getTime());
  if (isInvalidateDate) return date;
  return d.toLocaleDateString("en-US"); // "1/1/2021"
};

export const getDocumentHelpInfo = (document: TFlatDocument): TDocumentHelpInfo | null => {
  // a document["docType"] must match a key in the DOCUMENT_HELP_INFO_MAP to retrieve help info
  if (!document.docType) return null;
  return DOCUMENT_HELP_INFO_MAP[document.docType] || null;
};

export const isValidDocumentsResponseDTO = (data: TDocumentsResponseDTO | undefined) => {
  // making sure it exists 
  if (!data) return false;
  // overly cautious check -- making sure it won't break when calling dot notation. Should be an object in the shape of TDocumentsResponseDTO
  if (typeof data !== "object" || Array.isArray(data)) {
    console.warn("documents:: invalid data type received"); // debugging console, check network tab for errors
    return false;
  }
  return true;
};

export const shouldHideCustomerCareDocuments = (user: User | null) => !user?.isContractedInHubspot;

export const shouldShowEmptyDocumentsState = (
  data: TDocumentsResponseDTO | null,
  user: User | null,
) => {
  if (!data || !user) return true;
  return !data.docusign?.length && !data.customerCare?.length;
};

export const getDisplayableDocumentsForUser = (
  data: TDocumentsResponseDTO | undefined,
  user: User | null,
): TDocumentsViewProps["documentsData"] => {
  if (!data || !user) return null;
  return {
    ...data,
    customerCare: shouldHideCustomerCareDocuments(user)
      ? []
      : data.customerCare,
  };
};
