import { Box } from "@mui/system";

export const DigitalWarrantyManual = () => {
  return (
    <Box>
      <p>Digital Warranty Manual Coming soon...</p>
    </Box>
  );
};

export const DigitalWarrantyManualFullPage = () => {
  return (
    <Box p="20px">
      <DigitalWarrantyManual />
    </Box>
  );
};
