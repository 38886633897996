export const QUERY_KEYS = {
  contractedHomes: "contracted-homes",
  documents: "documents",
  marketing: "marketing",
  preferences: "preferences",
  savedDesigns: "saved-designs",
  savedHomes: "saved-homes",
  faqs: "faqs",
  team: "team",
  user: "user",
  auth: "auth"
};
