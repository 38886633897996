import type { TButtonLinkProps, TButtonListSectionProps } from "./types";
import { PreferredLenderIcon } from "_global/assets/icons/components/PreferredLenderIcon";
import { FindYourCommunityIcon } from "_global/assets/icons/components/FindYourCommunityIcon";
import { HomeOwnershipCalculatorIcon } from "_global/assets/icons/components/HomeOwnershipCalculatorIcon";
import { HousingMarketReportIcon } from "_global/assets/icons/components/HousingMarketReportIcon";

const BUTTON_LIST: TButtonLinkProps[] = [
  {
    text: "Save $5,000 in Closing Costs When Using Hixon Mortgage*",
    isExternalLink: true,
    icon: PreferredLenderIcon,
    to: "https://pahlischhomes.com/preferred-lenders?utm_source=wayfinder&utm_medium=prospective+dashboard&utm_campaign=wayfinder",
  },
  {
    text: "Homeownership Calculators",
    isExternalLink: true,
    icon: HomeOwnershipCalculatorIcon,
    to: "https://myhome.freddiemac.com/resources/calculators",
  },
  {
    text: "Which Community Is Right for You?",
    isExternalLink: true,
    icon: FindYourCommunityIcon,
    to: "https://pahlischhomes.com/?utm_source=wayfinder&utm_medium=prospective+dashboard&utm_campaign=wayfinder#quiz",
  },
  {
    text: "Interior Design Trends 2025: Biophilic Design and Maximalism",
    isExternalLink: true,
    icon: HousingMarketReportIcon,
    to: "https://pahlischhomes.com/blog/interior-design-trends-2025-biophilic-design-and-maximalism",
  },
];

export const SECTION_VALS: TButtonListSectionProps = {
  sectionTitle: "Start Laying the Groundwork",
  description: "Some tools to help you get started on your journey.",
  buttonList: BUTTON_LIST,
};
