import React from "react";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { useGetFAQs } from "../hooks/useGetFAQs";
import type { TFAQData } from "../types";

export type TFAQsPageProps = {
  faqsList: TFAQData[];
  isError?: boolean;
  isLoading: boolean;
};

type FAQsControllerProps = {
  component: React.FC<TFAQsPageProps>;
};

export const FAQsController: React.FC<FAQsControllerProps> = ({
  component: Component,
}) => {
  const { user } = useGlobalContext();

  const {
    data,
    isError,
    isLoading,
  } = useGetFAQs({ enabled: !!user });

  const faqsList = React.useMemo(() => {
    if (!data || !Array.isArray(data) || !data.length) return [];
    return data;
  }, [data]);

  return (
    <Component
      faqsList={faqsList}
      isError={isError}
      isLoading={isLoading}
    />
  );
};
