import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

// TODO: use ENV variables to determine base URL
const getBaseUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    case "local":
      return "http://localhost:3030/api/v1";
    case "staging":
      return "https://wayfinder-platform-api-staging.azurewebsites.net/api/v1";
    case "release":
      return "https://wayfinder-platform-api-release.azurewebsites.net/api/v1";
    case "production":
      return "https://wayfinder-platform-api.azurewebsites.net/api/v1";
    default:
      return "https://wayfinder-platform-api-staging.azurewebsites.net/api/v1";
  }
};

export const getApiClient = (token: string) => {
  const instance = axios.create({
    baseURL: getBaseUrl(),
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return instance;
};

// DEPRECATING THIS HOOK -- USE AN ASYNC VERSION OF getApiClient INSTEAD
export const useApiClient = () => {
  const { getAccessTokenSilently } = useAuth0();

  axios.interceptors.request.use(async (config) => {
    config.baseURL = getBaseUrl();

    // TODO: make axios config typesafe
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    config.headers = {
      "Content-type": "application/json",
    };

    if (typeof config.headers.Authorization === "undefined") {
      config.headers.Authorization = `Bearer ${await getAccessTokenSilently()}`;
    }
    return config;
  });

  return axios;
};
