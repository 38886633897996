import type { TSavedDesign } from "./types";

export function mapSavedDesignsOutdatedOptions(savedDesigns: TSavedDesign[]): TSavedDesign[] {
  return savedDesigns.map((design) => {

    return {
      ...design,
      hasOutdatedOptions: false,
      outdatedOptionInfo: {
        ids: [],
        errorLevel: "warning",
      },
    };
  });
}
