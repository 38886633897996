import { PrimaryButton } from "_global/components/Buttons/PrimaryButton";
import { ThemeButton } from "_global/components/Buttons/ThemeButton";
import { SectionTile } from "_global/components/Containers";
import { Typography } from "_global/components/Typography/Typography";
import { ReactComponent as TicketIcon } from "_global/assets/icons/ticket-icon.svg";

const TITLE = "Submit an Issue";
const SERVICE_REQUEST_TEXT = "Whether you have a maintenance question, want to know more about your one-year warranty inspection, our team is here for you.";
const EMERGENCY_HEADER = "Do you have an emergency?";
const EMERGENCY_TEXT = "If you are having water supply leaks, electrical failures, natural gas leaks, or HVAC heat system failures please reach out via our emergency line.";
const EMERGENCY_PHONE = "855-446-6763";

const linkStyles = {
  textDecoration: "unset",
  color: "unset",
  borderRadius: "24px",
};

type Props = {
  openServiceRequestDrawer: () => void;
};

export const HomeownerServiceRequestCard = ({ openServiceRequestDrawer }: Props) => (
  <SectionTile.Container variant="info" gap="20px" maxWidth={350} minWidth={330} maxHeight="max-content">
    <SectionTile.Header
      semanticTag="h2"
      title={TITLE}
      icon={TicketIcon}
    />
    <SectionTile.TextBlock innerContent={SERVICE_REQUEST_TEXT} />
    <PrimaryButton
      size="medium"
      onClick={openServiceRequestDrawer}
      label="Submit a Service Request"
    />
    <Typography.H4 semanticTag="h3">{EMERGENCY_HEADER}</Typography.H4>
    <SectionTile.TextBlock innerContent={EMERGENCY_TEXT} />
    <a
      title={`Click to call emergency phone line: ${EMERGENCY_PHONE}`}
      href={`tel:${EMERGENCY_PHONE}`}
      style={linkStyles}
    >
      <ThemeButton
        variant="danger"
        size="medium"
        tabIndex={-1}
        aria-hidden
        label={`Emergency Line: ${EMERGENCY_PHONE}`}
      />
    </a>
  </SectionTile.Container>
);
