import React from "react";
import { Stack } from "@mui/system";
import { RightDrawerModal } from "_global/components/Drawers/RightDrawer/RightDrawer";
import { ErrorDisplay, LoadingDisplay } from "./ServiceRequestDrawer.elements";
import { useGlobalContext } from "_global/contexts/GlobalContext";

type Props = {
  open: boolean;
  onClose: () => void;
};

const IFRAME_URL = "https://api.pahlischhomes.com/gfembed/?f=131";
const isHubspotProd = process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "release";

export const ServiceRequestFormDrawer = ({ open, onClose }: Props) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const { lotId } = useGlobalContext();

  const iframeUrl = React.useMemo(() => {
    if (!lotId) return IFRAME_URL;
    
    // Service Request Form is not set up for hubspot sandbox, it only talks to hubspot prod
    // so we should not use the dynamic lotId in staging/dev

    if (isHubspotProd) {
      return `${IFRAME_URL}&lot=${lotId}`;
    } else {
      return `${IFRAME_URL}&lot=123-45-678`;
    }
  }, [lotId]);

  const onLoad = React.useCallback(() => setLoading(false), []);
  const onError = React.useCallback(() => setError(true), []);

  return (
    <RightDrawerModal
      id="gravity-forms-drawer"
      title="Contact Customer Care"
      hideTitle
      minWidth="376px"
      open={open}
      onClose={onClose}
    >
      <Stack minHeight="calc(100vh - 80px)" flexGrow={1} minWidth={{ md: "460px" }}>
        <Stack mt="-40px" minHeight="100%" maxWidth="100%" position="relative" overflow="auto">
          {loading && <LoadingDisplay />} 
          {error ? (
            <ErrorDisplay />
          ) : (
            <iframe
              src={iframeUrl}
              width="100%"
              height="100%"
              style={{ opacity: loading ? 0 : 1, transition: "opacity 1.3s" }}
              frameBorder="0"
              className="gfiframe"
              onLoad={onLoad}
              onError={onError}
            />
          )}
        </Stack>
      </Stack>
    </RightDrawerModal>
  );
};
