import React from "react";
import { Box, Stack, styled } from "@mui/system";
import { useWindowResize } from "_global/hooks/useWindowResize";
import { BottomDrawerModal } from "_global/components/Drawers/BottomDrawer";
import { RightDrawerModal } from "_global/components/Drawers/RightDrawer/RightDrawer";
import { themeFonts } from "_global/theme/typography";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const StudioHelpDrawer: React.FC<Props> = (props) => {
  const { open, onClose } = props;
  const { windowSize, isTablet, isDesktop } = useWindowResize();
  const isMobile = !isTablet && !isDesktop;

  // Modal renders as a BottomDrawer on mobile, and a RightDrawer on tablet/desktop
  const DrawerModal = React.useMemo(() => (
    isMobile ? BottomDrawerModal : RightDrawerModal
  ), [isMobile]);
  
  if (!windowSize.width) return null; // prevent mounting before windowSize is available

  return (
    <DrawerModal
      id="studio-help-info-drawer"
      title="Help Bar"
      open={open}
      onClose={onClose}
    >
      <Stack gap="20px" pt="10px" pb="40px">
        <StyledTitle component="h4">Wayfinder Design Studio</StyledTitle>
        <Stack gap="0px" sx={{ maxWidth: { xs: "100%", sm: "420px" }}}>
          <StyledDescription component="p">{HELP_CONTENT_PARAGRAPHS[0]}</StyledDescription>
          <StyledDescription component="p">{HELP_CONTENT_PARAGRAPHS[1]}</StyledDescription>
          <StyledDescription component="p">{HELP_CONTENT_PARAGRAPHS[2]}</StyledDescription>
        </Stack>
      </Stack>
    </DrawerModal>
  );
};

const StyledTitle = styled(Box)(({ theme }) => ({
  margin: 0,
  color: theme.palette.black,
  ...themeFonts.textLgMedium,
}));

const StyledDescription = styled(Box)(({ theme }) => ({
  color: theme.palette.black,
  ...themeFonts.textLReading,
  [theme.breakpoints.down("sm")]: {
    fontSize: "15px",
    lineHeight: "24px",
  },
}));

const HELP_CONTENT_PARAGRAPHS = [
  "Welcome to the Wayfinder Design Studio, where you can explore your home's style with just a few clicks.",
  "This tool helps you visualize design combinations that suit your taste. Experiment with different options for cabinets, flooring, tile, and more.",
  "The Design Studio is intended for inspiration and not for creating an exact replica of your future home. Stay tuned for upcoming updates, including additional floor plans to explore!",
];

