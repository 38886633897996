import React from "react";
import { Box, styled } from "@mui/system";
import { FAQCardSkeleton } from "../FAQCard/FAQCard";

export const ListGrid = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(320px, 1fr))",
  gap: 14,
  listStyleType: "none",
  padding: 0,
  margin: 0,
  maxWidth: 900,
  [theme.breakpoints.down("lg")]: {
    maxWidth: 800,
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    minWidth: 300,
    maxWidth: "100%",
  },
}));

export const FAQCardListSkeleton = () => {
  const arr = React.useMemo(() => Array.from({ length: 8 }, (_, index) => index), []);
  return (
    <ListGrid component="ul">
      {arr.map((el) => (
        <Box key={el} component="li">
          <FAQCardSkeleton />
        </Box>
      ))}
    </ListGrid>
  );
};
