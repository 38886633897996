import { ReactComponent as CustomerCareIcon } from "_global/assets/icons/nav/customerCareIcon.svg";
import { ReactComponent as DashboardIcon } from "_global/assets/icons/nav/dashboardIcon.svg";
import { ReactComponent as DesignIcon } from "_global/assets/icons/nav/designIcon.svg";
import { ReactComponent as DocumentsIcon } from "_global/assets/icons/nav/documentsIcon.svg";
import { ReactComponent as ProfileIcon } from "_global/assets/icons/nav/profileIcon.svg";
import { ReactComponent as TeamIcon } from "_global/assets/icons/nav/teamIcon.svg";
import { ReactComponent as TimelineIcon } from "_global/assets/icons/nav/timelineIcon.svg";
import type { User } from "_global/types";

export type Route = {
  path: string;
  protectedPath?: string;
  publicPath?: string;
  subRoutes?: Record<string, Route>;
};

export type NavRoute = Route & {
  shortLabel?: string;
  label: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export const routes = {
  root: {
    path: "/",
  },
  error: {
    path: "/support",
  },
  dashboard: {
    path: "/dashboard",
  },
  login: {
    path: "/login",
  },
  logout: {
    path: "/logout",
  },
  onboarding: {
    path: "/onboarding",
  },
  timeline: {
    path: "/timeline",
  },
  team: {
    path: "/team",
  },
  customerCare: {
    path: "/customer-care",
    subroutes: {
      faqs: {
        path: "/customer-care/faqs",
      },
    },
  },
  // design and design-studio are public/private version of same page,
  // TODO: adjust this config schema as needed, it's preliminary
  designStudio: {
    path: "/design-studio",
    publicPath: "/design",
  },
  digitalWarrantyExpandedView: {
    path: "/digital-warranty-manual",
  },
  documents: {
    path: "/documents",
  },
  savedHomesFrame: {
    path: "/saved-homes/:wpId",
  },
  profile: {
    path: "/profile",
    subroutes: {
      savedHomes: {
        path: "/profile/saved-homes",
      },
      notifications: {
        path: "/profile/notifications",
      },
    },
  },
};

export const navRoutes: Record<string, NavRoute> = {
  dashboard: {
    path: routes.dashboard.path,
    label: "Dashboard",
    icon: DashboardIcon,
  },
  timeline: {
    path: routes.timeline.path,
    label: "Your Way Home",
    icon: TimelineIcon,
  },
  team: {
    path: routes.team.path,
    label: "Team",
    icon: TeamIcon,
  },
  customerCare: {
    path: routes.customerCare.path,
    label: "Customer Care",
    icon: CustomerCareIcon,
  },
  designStudio: {
    path: routes.designStudio.path,
    label: "Design Studio",
    shortLabel: "Design",
    icon: DesignIcon,
  },
  documents: {
    path: routes.documents.path,
    label: "Documents",
    icon: DocumentsIcon,
  },
  profile: {
    path: routes.profile.path,
    label: "Profile",
    icon: ProfileIcon,
  },
};

const NAV_ROUTE_LIST: NavRoute[] = [
  navRoutes.dashboard,
  navRoutes.timeline,
  navRoutes.team,
  navRoutes.customerCare,
  navRoutes.designStudio,
  navRoutes.documents,
  navRoutes.profile,
];

const BOTTOM_NAV_ROUTE_LIST: NavRoute[] = [
  navRoutes.dashboard,
  navRoutes.timeline,
  navRoutes.profile,
  navRoutes.designStudio,
];

const CONTRACTED_ONLY_ROUTES = [
  navRoutes.customerCare,
];

export const getNavRoutesForUser = (user: User | null): NavRoute[] => {
  if (!user) return []; // non-authenticated users not shown nav bars
  if (user.isContractedInHubspot) return NAV_ROUTE_LIST;
  return NAV_ROUTE_LIST.filter(
    (route) => !CONTRACTED_ONLY_ROUTES.includes(route),
  );
};

export const getBottomBarRoutesForUser = (
  user: User | null,
): { primaryLinks: NavRoute[]; drawerLinks: NavRoute[] } => {
  const allLinks = getNavRoutesForUser(user);
  const primaryLinks = user ? BOTTOM_NAV_ROUTE_LIST : [];
  const drawerLinks = allLinks.filter((link) => !primaryLinks.includes(link));
  return { primaryLinks, drawerLinks };
};
