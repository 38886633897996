import { TFAQData } from "./types";

const NEW_LINE_CHAR_VAL = 50;
const getNewLineCount = (text: string) => text.split("<strong>").length - 1;
const calculateLength = (text: string) =>
  text.length + getNewLineCount(text) * NEW_LINE_CHAR_VAL;

export const sortFAQsByLength = (FAQs: TFAQData[]) =>
  FAQs.sort(
    (a, b) =>
      calculateLength(a.answerHTML || "") - calculateLength(b.answerHTML || ""),
  );
