import React from "react";
import { useGlobalContext } from "_global/contexts/GlobalContext";
import { useGetDocuments } from "../hooks/useGetDocuments";
import { getDisplayableDocumentsForUser, isValidDocumentsResponseDTO } from "../utils";
import type { TDocumentsResponseDTO } from "../types";

export type TDocumentsViewProps = {
  isError: boolean;
  isLoading: boolean;
  documentsData: TDocumentsResponseDTO | null;
};

type DocumentsControllerProps = {
  component: React.FC<TDocumentsViewProps>;
};

export const DocumentsController: React.FC<DocumentsControllerProps> = ({
  component: Component,
}) => {
  const { user } = useGlobalContext();

  // both contracted and prospective buyers may have documents
  const shouldGetDocuments = !!user;

  const {
    data,
    isLoading,
    isError,
  } = useGetDocuments({ enabled: shouldGetDocuments });

  const documentsData = React.useMemo(() => (
    isValidDocumentsResponseDTO(data) ? getDisplayableDocumentsForUser(data, user) : null
  ), [data]);

  return (
    <Component
      documentsData={documentsData}
      isLoading={isLoading}
      isError={isError}
    />
  );
};
