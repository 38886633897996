import { Link } from "react-router-dom";
import { Stack, styled } from "@mui/system";
import { OpenInNew } from "@mui/icons-material";
import { PrimaryButton } from "_global/components/Buttons/PrimaryButton";
import { Typography } from "_global/components/Typography/Typography";
import { Image } from "_global/components/Images";

const OuterContainer = styled(Stack)(() => ({
  width: "100%",
  gap: "30px",
}));

const ContentContainer = styled(Stack)(({ theme }) => ({
  width: "100%",
  flexDirection: "row",
  gap: "30px",
  alignItems: "center",
  backgroundColor: theme.palette.grey[50],
  [theme.breakpoints.down("md")]: {
    padding: 0,
    flexDirection: "column",
  },
}));

const StyledThumbnailContainer = styled(Stack)(({ theme }) => ({
  alignSelf: "stretch",
  justifyContent: "center",
  alignItems: "center",
  minWidth: "186px",
  width: "30%",
  overflow: "hidden",
  borderRadius: "8px",
  [theme.breakpoints.down("md")]: {
    minWidth: "100%",
    width: "100%",
    height: "188px",
  },
}));

type ThumbnailProps = {
  src: string;
  alt: string;
};

const Thumbnail: React.FC<ThumbnailProps> = (props) => (
  <StyledThumbnailContainer>
    <Image
      {...props}
      height="100%"
      width="100%"
      borderRadius="8px"
      objectFit="cover"
      objectPosition="66% bottom"
    />
  </StyledThumbnailContainer>
);

type ButtonLinkProps = {
  to: string;
  label: string;
};

const ButtonLink: React.FC<ButtonLinkProps> = ({ to, label }) => (
  <Link
    to={to}
    target="_blank"
    style={{ textDecoration: "none", width: "100%" }}
  >
    <PrimaryButton tabIndex={-1} icon={OpenInNew} fullWidth label={label} />
  </Link>
);

const StyledText = styled(Typography.Body)(() => ({
  lineHeight: 1.75,
}));

export const StyledCard = {
  OuterContainer,
  ContentContainer,
  Thumbnail,
  ButtonLink,
  StyledText,
};
