import type { TDocumentHelpInfo, TDocumentFilterCategory } from "./types";

/** 
  * Document Help Info
  * Certain `document.docType` values are expected to map to a key in this object
  * the keys & titles (ie, "Accepted ITP") in this obj currently match each other, but that is not a requirement
  * the keys should match certain `docType` values, the title is just for display
**/

export const DOCUMENT_HELP_INFO_MAP: Record<string, TDocumentHelpInfo> = {
  "Accepted ITP": {
    title: "Accepted ITP",
    description: "The agreement before we go to contract. A formal agreement or “offer” in which a homebuyer establishes their intent to purchase a Pahlisch home.",
  },
  "Repair Request": {
    title: "Repair Request",
    description: "Items identified during the new homeowner demonstration that need to be corrected prior to closing.",
  },
  "Notice to Close": {
    title: "Notice to Close",
    description: "Formal notice that provides vital closing dates as we are near closing. Used when we don't have a completion date when the contract is signed.",
  },
  "Community Specifications": {
    title: "Community Specifications",
    description: "Detailed list of items that are included in your home.",
  },
  "Accepted Contract": {
    title: "Accepted Contract",
    description: "Sales Contract signed by both buyer and seller. Real estate purchase agreement, which is legally binding and agreed to by all parties.",
  },
  "Change Order": {
    title: "Change Order",
    description: "Upgrades, options, or other changes from your base specifications.",
  },
  "Plot Plan": {
    title: "Plot Plan",
    description: "Dimensional drawing of foundation and how the home will fit on the lot.",
  },
  "Addendum": {
    title: "Addendum",
    description: "An official change to the original purchase and sale agreement.",
  },
  "Seller's Property Disclosure Statement": {
    title: "Seller's Property Disclosure Statement",
    description:  "Required disclosure statement.",
  },
} as const;

export const DOCUMENT_CATEGORIES_LABEL_MAP: Record<TDocumentFilterCategory, string> = {
  "purchase": "Purchase Documents",
  "nhd": "NHD", // AKA Customer Care Documents
} as const;

export const DOCUMENT_CATEGORIES_LIST: [TDocumentFilterCategory, string][] = Object.entries(DOCUMENT_CATEGORIES_LABEL_MAP) as [TDocumentFilterCategory, string][];
