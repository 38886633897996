import { Box } from "@mui/system";
import { UnstyledButton } from "_global/components/Buttons/UnstyledButton";

type Props = {
  onClearSearch: () => void;
};

export const FAQCardListEmptySearch = ({ onClearSearch }: Props) => {
  return (
    <Box
      sx={{
        color: theme => theme.palette.v2.neutral[90],
        fontWeight: 300,
        fontSize: "16px",
        paddingLeft: "8px",
      }}
    >
      No results found. 
      <UnstyledButton
        onClick={onClearSearch}
        sx={{ color: theme => theme.palette.v2.colorTextLink, marginLeft: "8px", letterSpacing: "0.15px" }}
      >
        Clear search
      </UnstyledButton>
      .
    </Box>
  );
};

export const FAQCardListAPIError = () => {
  return (
    <Box
      sx={{
        color: theme => theme.palette.v2.neutral[90],
        fontWeight: 300,
        fontSize: "16px",
        paddingLeft: "8px",
      }}
    >
      An error occurred while fetching FAQs. Please try again later.
    </Box>
  );
};
