import React from "react";
import { Stack } from "@mui/system";

import { LogoutButton } from "./LogoutButton";
import { PrimaryNavItem } from "./PrimaryNavItem";
import { GradientDivider, StyledListItem } from "./PrimaryNavMenu.elements";
import { List, ListItem } from "_global/components/List/List";

import { getNavRoutesForUser } from "../../../util/routes";
import { NavigationSidebarContext } from "../../../contexts/NavigationSidebarContext";
import { useGlobalContext } from "_global/contexts/GlobalContext";

export const PrimaryNavMenu = () => {
  const { expanded } = React.useContext(NavigationSidebarContext);
  const { user } = useGlobalContext();
  const links = React.useMemo(() => getNavRoutesForUser(user), [user]);
  
  return (
    <Stack width="100%" component="nav" flexGrow={1} justifyContent="space-between">
      <Stack
        flex="1"
        component={List}
        divider={<GradientDivider aria-hidden="true" hide={!expanded} />}
        >
        {/* MAIN NAV LINKS */}
        {links.map((link) => (
          <StyledListItem component={ListItem} key={link.path}>
            <PrimaryNavItem {...link} />
          </StyledListItem>
        ))}
        {/* SECONDARY LINKS */}
        <Stack component={ListItem} justifyContent="flex-end" flex="2">
          <Stack component={List}>
            <StyledListItem component={ListItem}>
              <LogoutButton />
            </StyledListItem>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
