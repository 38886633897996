import React from "react";
import { Box, Stack, styled, type StackTypeMap } from "@mui/system";
import type { SvgIconComponent } from "@mui/icons-material";

import { Tile, type StyledTileProps } from "_global/components/Containers";
import { Typography, type TypographyProps } from "_global/components/Typography/Typography";
import { themeFonts } from "_global/theme/typography";
import type { ThemedTemplateVariants } from "_global/theme/types";

type TSectionTileProps = StyledTileProps & StackTypeMap["props"];

export const SectionTileContainer: React.FC<TSectionTileProps> = ({
  gap = "32px",
  maxWidth = "925px",
  children,
  ...tileProps
}) => {
  return (
    <Tile
      gap={gap}
      maxWidth={maxWidth}
      {...tileProps}
    >
      {children}
    </Tile>
  );
};

const StyledTextBlock = styled(Stack)(() => ({
  ...themeFonts.textLg,
  letterSpacing: "0.125px",
}));

type SectionTextBlockProps = {
  innerContent: string | React.ReactNode;
};

export const SectionTextBlock: React.FC<SectionTextBlockProps> = ({
  innerContent,
  ...stackProps
}) => (
  <StyledTextBlock {...stackProps}>
    {innerContent}
  </StyledTextBlock>
);

type StyledIconContainerProps = {
  iconColorVariant?: ThemedTemplateVariants;
};

const options = {
  shouldForwardProp: (prop: string) => !["iconColorVariant"].includes(prop),
};

const StyledIconContainer = styled(Box, options)<StyledIconContainerProps>(({ theme, iconColorVariant }) => ({
  display: "inline-flex",
  fontSize: "28px",
  "& > svg": {
    fill: iconColorVariant ? theme.palette.v2[iconColorVariant].main : "inherit",
  },
}));

type SectionHeaderProps = {
  title: string;
  semanticTag?: TypographyProps["semanticTag"];
  description?: SectionTextBlockProps["innerContent"];
  icon?: SvgIconComponent | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  adornmentLeft?: React.ReactNode;
} & StyledIconContainerProps;

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  semanticTag = "h2",
  adornmentLeft,
  description,
  icon: Icon,
  iconColorVariant,
}) => (
  <Stack gap="12px">
    <Box display="flex" alignItems="center" gap="18px">
      {adornmentLeft}
      {!!Icon && (
        <StyledIconContainer iconColorVariant={iconColorVariant}>
          <Icon fontSize="inherit" />
        </StyledIconContainer>
      )}
      <Typography.H4 semanticTag={semanticTag}>
        {title}
      </Typography.H4>
    </Box>
    {!!description && (
      <SectionTextBlock innerContent={description} />
    )}
  </Stack>
);
