import React from "react";
import { useGetSavedDesigns } from "../hooks/useGetSavedDesigns";
import { useCreateSavedDesign } from "../hooks/useCreateSavedDesign";
import { useUpdateSavedDesign,type TUpdateSavedDesignMutationFunc } from "../hooks/useUpdateSavedDesign";
import { useDeleteSavedDesign, type TDeleteSavedDesignMutationFunc } from "../hooks/useDeleteSavedDesign";
import { useCreateSavedDesignFromVR360Cache } from "features/DesignStudio/hooks/useVR360Cache";
import { mapSavedDesignsOutdatedOptions } from "../utils";
import type { TSavedDesign } from "../types";

export type TSavedDesignsViewProps = {
  savedDesigns: TSavedDesign[];
  isError: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isUpdateError: boolean;
  isUpdateLoading: boolean;
  updateSavedDesign: TUpdateSavedDesignMutationFunc;
  deleteSavedDesign: TDeleteSavedDesignMutationFunc;
};

type SavedDesignsControllerProps = {
  component: React.FC<TSavedDesignsViewProps>;
};

export const SavedDesignsController: React.FC<SavedDesignsControllerProps> = ({
  component: Component,
}) => {
  const {
    data,
    isError,
    isLoading,
    isFetching,
  } = useGetSavedDesigns();

  const {
    mutate: deleteSavedDesign,
    ...deleteFn
  } = useDeleteSavedDesign();

  const {
    mutate: updateSavedDesign,
    ...updateFn
  } = useUpdateSavedDesign();

  const {
    mutate: createSavedDesign,
    ...createFn
  } = useCreateSavedDesign();

  useCreateSavedDesignFromVR360Cache({ createSavedDesign });

  const isMutationError = updateFn.isError || deleteFn.isError;
  const isMutationUpdating = updateFn.isLoading || deleteFn.isLoading || createFn.isLoading;

  const savedDesigns = React.useMemo(() => {
    if (!data) return [];
    return mapSavedDesignsOutdatedOptions(data);
  }, [data]);

  return (
    <Component
      savedDesigns={savedDesigns}
      isError={isError}
      isLoading={isLoading}
      isFetching={isFetching}
      isUpdateLoading={isMutationUpdating}
      isUpdateError={isMutationError}
      updateSavedDesign={updateSavedDesign}
      deleteSavedDesign={deleteSavedDesign}
    />
  );
};
