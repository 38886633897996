import React from "react";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import { useApiClient } from "_global/hooks/useApi";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import { QUERY_KEYS } from "_global/constants";
import type { TFAQData } from "../types";

type UseGetFAQsOptions = {
  enabled?: boolean;
};

type TFAQDataDTO = TFAQData[];

export const useGetFAQs = (options?: UseGetFAQsOptions): UseQueryResult<TFAQDataDTO, Error> => {
  const { enabled = true } = options || {};
  const { showErrorToast } = useToastRenderer();

  const client = useApiClient();

  const queryState = useQuery<TFAQDataDTO, Error>({
    enabled,
    queryKey: [QUERY_KEYS.faqs, "all"],
    queryFn: async () => {
      const response = await client.get<TFAQDataDTO>("/faqs");
      return response.data;
    },
  });

  const { isError } = queryState;

  React.useEffect(() => {
    if (queryState.isError) showErrorToast({ id: "err-fetch-faqs", message: "Error fetching FAQ info" });
  }, [isError]);

  return queryState;
};
