import React from "react";
import { Box, Stack } from "@mui/system";
import {
  FAQCardList,
  FAQCardListEmptySearch,
  FAQCardListAPIError,
  FAQCardListSkeleton,
  FAQSearchHeader,
} from "./components";
import { FAQsController, type TFAQsPageProps } from "./controllers/FAQsController";
import { sortFAQsByLength } from "./utils";

const SEARCH_LABEL = "Search on our frequently asked questions to get quick answers for questions on your home.";

const FAQPageContent: React.FC<TFAQsPageProps> = ({
  isLoading,
  faqsList,
  isError,
}) => {
  const faqListSorted = React.useMemo(() => sortFAQsByLength(faqsList), [faqsList]);

  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value);

  const faqListFiltered = React.useMemo(() => {
    const q = searchQuery.trim().toLowerCase();
    if (!q) return faqListSorted;
    return faqListSorted.filter((faq) => faq.rawText?.toLowerCase().includes(q));
  }, [faqListSorted, searchQuery]);

  const onClearSearch = () => setSearchQuery("");
  
  const shouldShowEmptySearch = !!faqListSorted.length && !faqListFiltered.length;
  const shouldShowError = isError && !isLoading && !faqsList.length;

  return (
    <Stack gap={{ xs: "28px", sm: "40px" }}>
      <FAQSearchHeader.Container>
        <FAQSearchHeader.SearchLabel>{SEARCH_LABEL}</FAQSearchHeader.SearchLabel>
        <FAQSearchHeader.SearchBar value={searchQuery} onChange={onSearchChange} />
      </FAQSearchHeader.Container>
      <Box overflow="auto">
        {isLoading && <FAQCardListSkeleton />}
        <FAQCardList faqList={faqListFiltered} />
        {shouldShowEmptySearch && (
          <FAQCardListEmptySearch onClearSearch={onClearSearch} />
        )}
      </Box>
      {shouldShowError && <FAQCardListAPIError />}
    </Stack>
  );
};

export const FAQPage = () => <FAQsController component={FAQPageContent} />;
