import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getApiClient } from "_global/hooks/useApi";
import { useToastRenderer } from "_global/components/Toast/hooks/useToastRenderer";
import { TOAST_DATA } from "./constants";
import { QUERY_KEYS } from "_global/constants";
import type { TSavedDesign } from "../types";

type UseGetSavedDesignOptions = {
  id: TSavedDesign["id"] | null;
  enabled: boolean;
  token: string | null;
};

export const useGetSavedDesign = ({
  id,
  enabled,
  token,
}: UseGetSavedDesignOptions) => {
  const { showErrorToast } = useToastRenderer();
  const client = getApiClient(token || "");
  const shouldFetch = !!id && enabled;

  const { data, isStale, isFetching, isLoading, isError } = useQuery<
    TSavedDesign,
    Error
  >({
    enabled: shouldFetch,
    queryKey: [QUERY_KEYS.savedDesigns, "get", id],
    queryFn: async () => {
      const response = await client.get<TSavedDesign>(`/saved-designs/${id}`);
      return response.data;
    },
  });

  React.useEffect(() => {
    if (isError) showErrorToast(TOAST_DATA.fetchDesignErr);
  }, [isError]);

  return {
    data,
    isStale,
    isLoading,
    isFetching,
    isError,
  };
};
