import { styled, Box } from "@mui/system";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
  width: "100%",
  maxWidth: "900px",
  borderRadius: "20px",
  [theme.breakpoints.down("sm")]: {
    backgroundColor: theme.palette.v2.specific.lightSeaGreen,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8),
  },
}));

const StyledSearchBar = styled("input")(({ theme }) => ({
  width: "100%",
  padding: "10px 16px",
  borderRadius: "50px",
  border: "1px solid #C9C9C9",
  minHeight: "40px",
  maxWidth: "700px",
  outline: "1px solid transparent",
  transition: "border-color 0.075s ease-in-out",
  "&:focus": {
    borderColor: theme.palette.v2.primary.main,
  },
}));

const StyledHeaderText = styled("label")(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 300,
  lineHeight: "28px",
  color: theme.palette.v2.neutral[85],
  [theme.breakpoints.down("sm")]: {
    color: theme.palette.v2.neutral[90],
  },
}));

const SearchBar = (props: {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string
}) => {
  return (
    <StyledSearchBar
      id="faq-search-bar"
      type="search"
      placeholder="Search for..."
      {...props}
    />
  );
};

const SearchLabel = (props: React.PropsWithChildren) => <StyledHeaderText htmlFor="faq-search-bar" {...props} />;

export const FAQSearchHeader = {
  Container,
  SearchBar,
  SearchLabel,
};
