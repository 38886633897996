import { Outlet } from "react-router-dom";
import { Stack } from "@mui/system";
import { PageHeader } from "features/PageHeader";
import { Tabs, type TNavTabProps } from "_global/components/Tabs";
import { routes } from "features/Navigation/util/routes";

const NAV_TABS: TNavTabProps[] = [
  {
    label: "Profile",
    path: routes.profile.path,
  },
  {
    label: "Saved Homes",
    path: routes.profile.subroutes.savedHomes.path,
  },
  {
    label: "Contact Preferences",
    path: routes.profile.subroutes.notifications.path,
  },
];

export const ProfileRoot = () => {
  return (
    <Stack gap="40px">
      <PageHeader title="Profile" />
      <Tabs.TabListContainer>
        {NAV_TABS.map((navTabProps) => (
          <Tabs.NavTab key={navTabProps.path} {...navTabProps} />
        ))}
      </Tabs.TabListContainer>
      <Outlet />
    </Stack>
  );
};
